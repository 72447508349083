//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import constant from '~/const';

export default {
  props: {
    className: { type: String, default: '' },
    close: { type: Boolean, default: false },
    color: { type: String, default: '' },
    quantity: { type: Number, default: null },
    isSelected: { type: Boolean, default: false },
  },

  computed: {
    getColor() {
      const { DEFAULT_USER_TAG_COLOR } = constant.users;
      const { color } = this;

      if (!color) {
        return DEFAULT_USER_TAG_COLOR;
      }
      return color;
    },

    getTextColor() {
      const textColor = this.hexToRgb('#121212');
      const backgroundColor = this.hexToRgb(this.getColor);
      let res;

      // Преобразуем цвета в числовые значения яркости
      const textBrightness = this.calculateBrightness(textColor);
      const backgroundBrightness = this.calculateBrightness(backgroundColor);

      // Оцениваем контрастность текста на фоне
      const contrastRatio = (Math.max(textBrightness, backgroundBrightness) + 0.05) / (Math.min(textBrightness, backgroundBrightness) + 0.05);

      // Если контрастность недостаточна (обычно минимальное значение контрастности равно 4.5 для большинства контента),
      // изменяем цвет текста
      if (contrastRatio < 10.5 || contrastRatio > 400) {
        res = false;
      } else {
        res = true;
      }
      return res;
    },
  },

  methods: {
    closeHandler() {
      this.$emit('remove');
    },

    click() {
      this.$emit('click');
    },

    hexToRgb(hex) {
      // Убираем символ #, если он есть
      const fixedHex = hex.replace(/^#/, '');

      // Разбираем HEX на составляющие (красную, зеленую, синюю)
      const r = parseInt(fixedHex.substring(0, 2), 16);
      const g = parseInt(fixedHex.substring(2, 4), 16);
      const b = parseInt(fixedHex.substring(4, 6), 16);

      // Возвращаем RGB в формате строки
      return `rgb(${r}, ${g}, ${b})`;
    },

    calculateBrightness(color) {
    // Удаляем лишние символы из строки (например, "rgb(", ")" и лишние пробелы)
      const cleanedColor = color.substring(4, color.length - 1).replace(/ /g, '').split(',');
      // Рассчитываем яркость по формуле
      return (parseInt(cleanedColor[0], 16) * 299 + parseInt(cleanedColor[1], 16) * 587 + parseInt(cleanedColor[2], 16) * 114) / 1000;
    },
  },
};
