//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    user: { type: Object, default: () => ({}) },
  },

  data() {
    return {
      isUserTimeZone: true,
    };
  },

  methods: {
    getUserBrowser(value) {
      const dataObj = this.$utils.getParsedUA(value);
      if (dataObj.browser && dataObj.os) {
        return `
          ${dataObj.browser.name || this.$t('Unknown browser')},
          ${dataObj.os.name || this.$t('unknown OS')}
        `;
      }
      return '';
    },

    swapTimezone() {
      this.isUserTimeZone = !this.isUserTimeZone;
    },
  },
};
