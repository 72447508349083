//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import constant from '~/const';
import currencyForm from './currencyForm.vue';

export default {
  components: {
    currencyForm,
  },

  props: {
    value: { type: Boolean, default: false },
    showMerchantFees: { type: Boolean, default: false },
    showTraderFees: { type: Boolean, default: false },
    data: { type: [Array, Object], default: () => ([]) },
    currencies: { type: Array, default: () => ([]) },
  },

  data() {
    return {
      settings: [],
      constant,
    };
  },

  computed: {
    model: {
      get() {
        const { value } = this;
        return value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    isDataExist() {
      const { settings } = this.$props.data;
      if (Array.isArray(settings)) {
        return settings.length > 0;
      }

      if (typeof settings === 'object') {
        return Object.keys(settings).length > 0;
      }
      return false;
    },

    filteredCurrencies() {
      return this.currencies.filter((currency) => this.$props.data.currencies?.includes(currency.id));
    },

    filteredSettings() {
      const ids = this.$props.data.settings.map((elem) => elem.currencyId);
      const editableCurrencies = this.filteredCurrencies.filter((elem) => !ids.includes(elem.id));

      return editableCurrencies.concat(this.currencies.filter((currency) => ids.includes(currency.id))).reverse();
    },

    currencyId() {
      return this.input.currencyId ?? 0;
    },
  },

  watch: {
    value(val) {
      if (val) {
        this.input = {};
      }
    },
  },

  methods: {
    apply() {
      const { settings } = this;
      this.$emit('apply', { settings });
    },

    saveFees(data, currencyId) {
      const index = this.settings.findIndex((elem) => elem.currencyId === currencyId);
      if (index >= 0) {
        this.settings[index] = { ...data, currencyId, userId: this.$route.params.id };
      } else {
        this.settings = [...this.settings, { ...data, currencyId, userId: this.$route.params.id }];
      }
    },

    getFeePercent(type, item) {
      const foundElem = this.$props.data.settings.find((elem) => elem.currencyId === item);
      return foundElem ? foundElem[`${type}FeePercent`] : 0;
    },

    getProfitPercent(type, item) {
      const foundElem = this.$props.data.settings.find((elem) => elem.currencyId === item);
      return foundElem ? foundElem[`${type}ProfitPercent`] : 0;
    },

    getEnabledStatus(item) {
      const foundElem = this.$props.data.settings.find((elem) => elem.currencyId === item);
      return foundElem ? foundElem.isEnabled : true;
    },

    close() {
      this.$emit('close');
    },
  },
};
