//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';

export default {
  props: {
    currencyName: { type: String, default: '' },
    propBuyPercent: { type: Number, deafult: undefined },
    propSellPercent: { type: Number, deafult: undefined },
    isMerchant: { type: Boolean, deafult: undefined },
    propIsEnabled: { type: Boolean, default: undefined },
  },

  data() {
    return {
      data: {
        sellProfitPercent: undefined,
        sellFeePercent: undefined,
        buyProfitPercent: undefined,
        buyFeePercent: undefined,
        isEnabled: undefined,
      },
      regex: new RegExp(/^[0-9]+([.,][0-9]+)?$/),
    };
  },

  created() {
    this.data.isEnabled = this.$props.propIsEnabled;
  },

  methods: {
    ...mapState('tradersFees', { generalFees: 'data' }),

    saveContent() {
      this.$emit('currency-fee-data', this.data);
    },

    /**
     * Returns a placeholder string based on the given percent, type, and author.
     *
     * @param {number} percent - The percent value.
     * @param {string} type - The type of the placeholder.
     * @param {string} author - The author of the placeholder.
     * @return {string} The placeholder string.
     */
    getPlaceholder(percent, type, author) {
      const index = `${[`${author}${type}`]}`;
      if (this.$props.isMerchant) {
        return percent > 0 ? `${this.$t('Personal')}: ${percent}` : `${this.$t('General')}: ${this.generalFees()[index]}`;
      }
      return percent > 0 ? `${this.$t('Personal')}: ${percent}` : `${this.$t('General')}: ${this.generalFees()[index]}`;
    },

    saveBuyFeePercent(percent) {
      if (this.regex.test(percent)) {
        this.data.buyFeePercent = Number(percent);
        this.saveContent();
      }
    },

    saveSellFeePercent(percent) {
      if (this.regex.test(percent)) {
        this.data.sellFeePercent = Number(percent);
        this.saveContent();
      }
    },

    saveBuyProfitPercent(percent) {
      if (this.regex.test(percent)) {
        this.data.buyProfitPercent = Number(percent);
        this.saveContent();
      }
    },

    saveSellProfitPercent(percent) {
      if (this.regex.test(percent)) {
        this.data.sellProfitPercent = Number(percent);
        this.saveContent();
      }
    },

    saveIsEnabled(boolean) {
      this.data.isEnabled = boolean;
      this.saveContent();
    },
  },
};
