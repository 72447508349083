//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import Tag from '~/components/tags/Tag.vue';
import TagsSettings from '~/components/tags/Settings.vue';

export default {
  components: {
    Tag,
    TagsSettings,
  },

  data() {
    return {
      showSettings: false,
    };
  },

  computed: {
    ...mapState('users', ['userTagsList']),
  },

  methods: {
    add(tagId) {
      this.$emit('add', tagId);
    },
  },
};
